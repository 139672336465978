import * as React from "react"
import Layout from "../components/Layout/Layout"
import { Link, graphql } from "gatsby"
import { FaChevronRight } from "react-icons/fa"
import { FaChevronLeft } from "react-icons/fa"

const styles = require ("./CommentTemplate.module.css")

function commentTemplate(props:any) {
    const post = props.data.markdownRemark;
    const { prev, next } = props.pageContext;      
    return (
        <Layout> 
            <div className={`${styles.pageNav}`}>     
                <ul className ={`${styles.pageNavMenu}`}>
                    <li>
                        {prev && (
                        <Link to={`${prev.fields.slug}`} rel="prev">
                            <FaChevronLeft /><span>{prev.frontmatter.title}</span>
                        </Link>
                        )}
                    </li>
                    <li><a className = "scrollTop" href="#___gatsby">{post.frontmatter.title}</a></li>
                    <li>
                        {next && (
                        <Link to={`${next.fields.slug}`} rel="next">
                           <span>{next.frontmatter.title}</span><FaChevronRight />
                        </Link>
                        )}
                    </li>
                </ul>
            </div>
            <div className="postContent">
                <h1>{post.frontmatter.title}</h1>
                     <h2>{post.frontmatter.subtitle}</h2>         
                <div dangerouslySetInnerHTML={{ __html:
                 post.html }} />            
            </div>        
        </Layout>
    )
}
export default commentTemplate

export const query = graphql`

    query PostQuery($slug: String!) {
      markdownRemark (fields: {slug: {eq: $slug}}) {
        id
        html
        fields{
          slug
        }
        excerpt
        frontmatter{
          title
          date
          subtitle
        }
      }
    }
`
